var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "figure",
    { style: [_vm.hasCover ? { "background-image": _vm.coverUrl } : {}] },
    [
      !_vm.hasCover && _vm.type == "book"
        ? _c("h5", [_vm._v(_vm._s(_vm.name))])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }