var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "subscription-required" },
    [
      _c("div", { staticClass: "container" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("reader.subscription-required")))]),
      ]),
      _c("Subscription", { attrs: { book: _vm.book } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }