import { render, staticRenderFns } from "./publication-month.vue?vue&type=template&id=2df3b57e&"
import script from "./publication-month.vue?vue&type=script&lang=js&"
export * from "./publication-month.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/bcc-literature/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2df3b57e')) {
      api.createRecord('2df3b57e', component.options)
    } else {
      api.reload('2df3b57e', component.options)
    }
    module.hot.accept("./publication-month.vue?vue&type=template&id=2df3b57e&", function () {
      api.rerender('2df3b57e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/books/overviews/publications/publication-month.vue"
export default component.exports