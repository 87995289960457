var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("loader", { ref: "loader" }, [
    _c("section", { staticClass: "not-found container" }, [
      _c("section", { staticClass: "center small" }, [
        _c("div", { staticClass: "large section-header" }, [
          _c("h3", [_vm._v(_vm._s(_vm.$t("not-found.title")))]),
        ]),
        _c("br"),
        _c("section", { staticClass: "list" }, [
          _c("h4", [_vm._v(_vm._s(_vm.$t("not-found.suggestions")))]),
          _c(
            "ol",
            { staticClass: "chapters" },
            _vm._l(_vm.translations, function (translation, index) {
              return _c("li", { key: index }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeBookLanguage(translation.language)
                      },
                    },
                  },
                  [
                    _c("h5", [
                      _c("img", {
                        staticClass: "flag",
                        attrs: {
                          width: "16",
                          height: "16",
                          src: "/assets/flags/" + translation.language + ".svg",
                          alt: translation.language,
                        },
                      }),
                      _vm._v(" " + _vm._s(translation.title) + " "),
                    ]),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }