var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", { staticClass: "search-form" }, [
    _c("input", {
      staticClass: "search",
      attrs: {
        type: "text",
        name: "Search for books",
        id: "search-books",
        placeholder: _vm.$t("search.search"),
        required: "",
      },
      on: {
        keyup: function ($event) {
          return _vm.$emit("query-changed", $event.target.value)
        },
        focus: function ($event) {
          _vm.focus = true
        },
        blur: function ($event) {
          _vm.focus = false
        },
      },
    }),
    _c("button", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.notEmpty || _vm.focus,
          expression: "notEmpty || focus",
        },
      ],
      staticClass: "button-reset",
      attrs: { type: "reset" },
      on: {
        click: function ($event) {
          return _vm.$emit("query-changed", "")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }