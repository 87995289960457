var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.notFound
        ? _c("not-found")
        : [
            _c(
              "section",
              { staticClass: "container reading-view" },
              [
                _c("app-header", {
                  attrs: {
                    backButtonRoute: _vm.getBackButtonRoute,
                    pageName: _vm.book ? _vm.book.title : "",
                  },
                }),
                _c("loader", { ref: "loader" }, [
                  _c(
                    "article",
                    {
                      staticClass: "center small",
                      style: { zoom: _vm.fontSize },
                    },
                    [
                      !_vm.articles ||
                      (_vm.book != null && _vm.showingFirstChapter)
                        ? [
                            _c("Title", {
                              attrs: {
                                book: _vm.book,
                                year: _vm.year,
                                month: _vm.month,
                                title: _vm.articles[0].title,
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm.isContentVisible
                        ? _c(
                            "ArticleScroller",
                            {
                              attrs: {
                                articles: _vm.articles,
                                loadTopHandler:
                                  _vm.isPublication || _vm.showingFirstChapter
                                    ? null
                                    : _vm.loadTop,
                                loadBottomHandler: _vm.isPublication
                                  ? null
                                  : _vm.loadBottom,
                                getElementName: (article) =>
                                  _vm.getElementName(article),
                                "no-lazy-load": _vm.isPublication,
                              },
                              on: {
                                change: (article) =>
                                  _vm.setCurrentChapter(article.chapterId),
                              },
                            },
                            _vm._l(_vm.articles, function (article) {
                              return _c("ArticleFull", {
                                key: article.id,
                                ref: "articles",
                                refInFor: true,
                                attrs: {
                                  article: article,
                                  highlight: _vm.wordsToHighlight,
                                  audioBookUrl: _vm.audioBookUrl,
                                  bookTitle: _vm.bookTitle,
                                  id: _vm.getElementName(article),
                                },
                              })
                            }),
                            1
                          )
                        : _c("SubscriptionRequired", {
                            attrs: { book: _vm.book },
                          }),
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
            _c("a", {
              staticClass: "toggle-sidebar button-circular main",
              attrs: { alt: "Toggle Sidebar" },
              on: {
                click: function ($event) {
                  _vm.showSidebar = !_vm.showSidebar
                },
              },
            }),
            _c("a", {
              staticClass: "share button-circular secondary",
              attrs: { alt: "Share" },
              on: { click: _vm.openShareModal },
            }),
            _c("app-sidebar", {
              on: {
                chapterChanged: _vm.changeChapter,
                close: function ($event) {
                  _vm.showSidebar = false
                },
              },
            }),
            _c("ShareLinkModal", {
              attrs: { url: _vm.shareUrl, message: _vm.shareMessage },
            }),
            _c("TextToSpeechPlayer", { attrs: { articles: _vm.articles } }),
          ],
      _c("div", { attrs: { id: "print-footer" } }, [
        _vm._v("© Copyright Skjulte Skatters Forlag N-4098 Tananger, Norway."),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }