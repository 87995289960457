var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showNotice
    ? _c("section", { staticClass: "notice error" }, [
        _c("h5", { domProps: { innerHTML: _vm._s(_vm.errorMessage) } }),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: { type: "button", "aria-label": "Close" },
            on: {
              click: function ($event) {
                return _vm.$store.commit("error/showError", false)
              },
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }