var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: { marginTop: _vm.topbarInitialized ? "0" : "48px" },
      attrs: { id: "main" },
    },
    [
      _c("router-view", {
        staticClass: "main-content",
        attrs: { id: "content" },
      }),
      _c("error-notice"),
      _c("app-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }