var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("app-header", {
        attrs: { showBackButton: false, pageName: _vm.$t("books.books") },
      }),
      _c(
        "section",
        { staticClass: "container intro", attrs: { id: "literature-intro" } },
        [
          _c("section", {
            staticClass: "center x-small",
            domProps: { innerHTML: _vm._s(_vm.$t("books.welcome-message")) },
          }),
        ]
      ),
      _c("section", { staticClass: "container" }, [
        _c("section", { staticClass: "center x-small" }, [_c("search-box")], 1),
        _c(
          "section",
          { staticClass: "banners center flex" },
          [_c("song-treasures-notice"), _c("user-voice-banner")],
          1
        ),
      ]),
      _c(
        "loader",
        [
          _c("grid", {
            attrs: {
              list: _vm.publications,
              title: _vm.$t("subscription.subscriptions"),
              layout: _vm.GridLayout.TILES,
              type: _vm.GridType.PUBLICATION,
            },
          }),
          _c(
            "SearchableGrids",
            [
              _c("grid", {
                attrs: {
                  list: _vm.books,
                  title: _vm.$t("books.books-all"),
                  layout: _vm.GridLayout.TILES,
                  type: _vm.GridType.BOOK,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }