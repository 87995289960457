import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import scrollBehavior from './scrollBehavior';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    scrollBehavior,
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (to.query.lang != null) {
        router.app.$store.commit('session/setAppLanguage', to.query.lang);
    }
    next();
});

export default router;