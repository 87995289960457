const GridType = {
    BOOK: 1,
    AUTHOR: 2,
    ARTICLE: 3,
    PUBLICATION:4
}

const GridLayout = {
    TILES: 1,
    LIST: 2
}

export {
    GridType,
    GridLayout
}