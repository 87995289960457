var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "chapter-header" },
        [
          _c(
            "h5",
            { attrs: { id: "chapter-title-" + _vm.article.chapterId } },
            [
              _vm._v(
                _vm._s(_vm.$t("reader.chapter")) +
                  " " +
                  _vm._s(_vm.article.chapterId)
              ),
            ]
          ),
          _vm.featureFlags().AudioOfArticles
            ? _c("TextToSpeechButton", {
                attrs: {
                  article: _vm.article,
                  audioBookUrl: _vm.audioBookUrl,
                  bookTitle: _vm.bookTitle,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.article.content != null
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.article.content) } })
        : _c("DocumentViewer", { attrs: { article: _vm.article } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }