var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "search-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          ;("onSubmit")
        },
      },
    },
    [
      _c("span", {
        staticClass: "main-search-button",
        on: { click: _vm.search },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchQuery,
            expression: "searchQuery",
          },
        ],
        staticClass: "main-search",
        attrs: {
          type: "text",
          name: "Search",
          placeholder: _vm.$t("search.search-box"),
        },
        domProps: { value: _vm.searchQuery },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.search.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.searchQuery = $event.target.value
          },
        },
      }),
      _c("span", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.searchQuery,
            expression: "searchQuery",
          },
        ],
        staticClass: "clear-search-input",
        on: { click: _vm.clearInput },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }