var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "subscription", class: { active: _vm.isSubscribed } },
    [
      _c("h5", [_vm._v(_vm._s(_vm.cardHeader))]),
      _c("h4", [_vm._v(_vm._s(_vm.element.title))]),
      _c("p", { staticClass: "small" }, [
        _vm._v(_vm._s(_vm.subscriptionMessage)),
      ]),
      _vm.isSubscribed
        ? _c(
            "article",
            [
              _c(
                "router-link",
                {
                  staticClass: "button-secondary small",
                  attrs: { to: _vm.trimHostname(_vm.element.publicationUrl) },
                },
                [_vm._v(_vm._s(_vm.$t("subscription.see-all")))]
              ),
              _c(
                "router-link",
                {
                  staticClass: "latest",
                  attrs: {
                    to: _vm.trimHostname(_vm.element.lastPublicationUrl),
                  },
                },
                [
                  _c("figure", {
                    style: { "background-image": _vm.coverImage },
                  }),
                  _c("h6", [
                    _vm._v(_vm._s(_vm.$t("subscription.latest-issue"))),
                  ]),
                  _c("h4", [_vm._v(_vm._s(_vm.element.lastPublicationTitle))]),
                ]
              ),
            ],
            1
          )
        : _c("article", [
            _c("figure", { style: { "background-image": _vm.coverImage } }),
            _c(
              "div",
              { staticClass: "signup-cta" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "button-main small",
                    attrs: { to: _vm.trimHostname(_vm.element.publicationUrl) },
                  },
                  [_vm._v(_vm._s(_vm.$t("subscription.learn-more")))]
                ),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }