var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "publication-month" },
    [
      _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "read-publication",
              params: {
                bookId: _vm.book.id,
                year: _vm.publicationMonth.year,
                month: _vm.publicationMonth.month,
              },
            },
          },
        },
        [
          _c("book-card-cover", { attrs: { "cover-id": _vm.book.coverId } }),
          _c("h3", [
            _vm._v(
              _vm._s(_vm._f("capitalize")(_vm.publicationMonth.monthName))
            ),
          ]),
          _c("p", { staticClass: "small" }, [_vm._v(_vm._s(_vm.chapterText))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }