var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("app-header", {
        attrs: { showBackButton: false, pageName: _vm.$t("authors.authors") },
      }),
      _c("loader", [
        _c("section", [
          _c(
            "section",
            { staticClass: "container author-library" },
            [
              _c(
                "SearchableGrids",
                [
                  _c("Grid", {
                    attrs: {
                      list: _vm.authorsPopular,
                      title: _vm.$t("authors.authors-popular"),
                      layout: _vm.GridLayout.TILES,
                      type: _vm.GridType.AUTHOR,
                    },
                  }),
                  _c("Grid", {
                    attrs: {
                      list: _vm.authorsAll,
                      title: _vm.$t("authors.authors-all"),
                      layout: _vm.GridLayout.LIST,
                      type: _vm.GridType.AUTHOR,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }