import { render, staticRenderFns } from "./la-loader.vue?vue&type=template&id=6c168a0b&"
import script from "./la-loader.vue?vue&type=script&lang=js&"
export * from "./la-loader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/bcc-literature/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c168a0b')) {
      api.createRecord('6c168a0b', component.options)
    } else {
      api.reload('6c168a0b', component.options)
    }
    module.hot.accept("./la-loader.vue?vue&type=template&id=6c168a0b&", function () {
      api.rerender('6c168a0b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/la-loader.vue"
export default component.exports