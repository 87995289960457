var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "search-result" },
    [
      _c(
        "router-link",
        {
          staticClass: "result-book-only",
          attrs: {
            to: {
              name: "book-index",
              params: { bookId: _vm.result.id, parent: _vm.$route },
            },
          },
        },
        [
          _c("book-card-cover", { attrs: { "cover-id": _vm.result.coverId } }),
          _c("div", { staticClass: "book-details" }, [
            _c("h4", [_vm._v(_vm._s(_vm.result.title))]),
            _c("p", [
              _vm._v(
                _vm._s(_vm.authorFullName) + " · " + _vm._s(_vm.result.year)
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }