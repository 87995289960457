import { render, staticRenderFns } from "./article-full.vue?vue&type=template&id=59a653f2&"
import script from "./article-full.vue?vue&type=script&lang=js&"
export * from "./article-full.vue?vue&type=script&lang=js&"
import style0 from "./article-full.vue?vue&type=style&index=0&id=59a653f2&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/bcc-literature/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59a653f2')) {
      api.createRecord('59a653f2', component.options)
    } else {
      api.reload('59a653f2', component.options)
    }
    module.hot.accept("./article-full.vue?vue&type=template&id=59a653f2&", function () {
      api.rerender('59a653f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/reader/article-full.vue"
export default component.exports