var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "list" }, [
    _c(
      "ol",
      { staticClass: "chapters" },
      _vm._l(_vm.chapters, function (chapter, index) {
        return _c(
          "li",
          { key: index },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: `${chapter.id}`,
                  append: "",
                  title: `(Chapter) #${chapter.id} – ${chapter.title}`,
                  alt: chapter.title,
                },
              },
              [_c("h5", [_vm._v(_vm._s(chapter.title))])]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }