var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.book != null
    ? _c(
        "div",
        [
          _c(
            "section",
            {
              staticClass: "book-header container",
              class: { "ebook-only": _vm.book.ebookOnly },
            },
            [
              _c(
                "section",
                { staticClass: "flex sm-flex-col center small" },
                [
                  _c("book-card-cover", {
                    attrs: { "cover-id": _vm.book.coverId },
                  }),
                  _c(
                    "section",
                    {
                      staticClass: "flex flex-col justify-center info",
                      class: { tall: _vm.bookId == 39 },
                    },
                    [
                      _c("h2", [_vm._v(_vm._s(_vm.book.title))]),
                      _c(
                        "h5",
                        [
                          _vm.book.author
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("book-index.written-by")) +
                                    " " +
                                    _vm._s(_vm.book.author.fullName) +
                                    " "
                                ),
                              ]
                            : _vm._e(),
                          _vm.book.author && _vm.book.year
                            ? [_vm._v(" · ")]
                            : _vm._e(),
                          _vm.book.year
                            ? [_vm._v(" " + _vm._s(_vm.book.year) + " ")]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("section", { staticClass: "actions" }, [
                        _vm.book.ebookId != null
                          ? _c(
                              "a",
                              {
                                class: [
                                  _vm.book.ebookOnly
                                    ? "button-main"
                                    : "button-secondary",
                                  "small",
                                ],
                                on: { click: _vm.getEbook },
                              },
                              [_vm._v(_vm._s(_vm.$t("book-index.get-ebook")))]
                            )
                          : _vm._e(),
                        !_vm.book.ebookOnly
                          ? _c(
                              "a",
                              {
                                staticClass: "button-main small",
                                on: { click: _vm.startReadingFirstChapter },
                              },
                              [_vm._v(_vm._s(_vm.$t("book-index.read-now")))]
                            )
                          : _vm._e(),
                        _vm.bookId == 39
                          ? _c(
                              "a",
                              {
                                staticClass: "button-main small",
                                on: { click: _vm.startReadingRandomChapter },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("book-index.read-random-chapters")
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.book.audioBookUrl != null
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "button-secondary small article-tts-btn bmm-icon",
                                on: { click: _vm.goToAudioBook },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("audiobooks.listen-on-bmm"))
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.book.audioBookUrl == null &&
                        _vm.featureFlags().AudioOfArticles
                          ? _c(
                              "a",
                              {
                                staticClass: "button-secondary small",
                                on: { click: _vm.playFromFirstChapter },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("audiobooks.play-audiobook"))
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.loadingChapters
            ? _c("loading-spinner")
            : !_vm.book.ebookOnly
            ? _c("section", { staticClass: "container" }, [
                _c(
                  "section",
                  { staticClass: "center small" },
                  [
                    _c("div", { staticClass: "section-header" }, [
                      _c("h5", [
                        _vm._v(_vm._s(_vm.$t("book-index.chapters")) + " "),
                        _c("span", [_vm._v(_vm._s(_vm.chapters.length))]),
                      ]),
                    ]),
                    _c("book-chapters-list", {
                      attrs: { chapters: _vm.chapters },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }