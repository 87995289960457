var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPaidSubscription && _vm.subscriptionLoaded && !_vm.isSubscribed
    ? _c("section", { staticClass: "container" }, [
        _c(
          "section",
          { staticClass: "center small" },
          [
            _c("SubscriptionForm", {
              attrs: {
                book: _vm.book,
                price: _vm.price,
                hasMultiModes: _vm.hasMultiModes,
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }