<template>
	<footer>
		<p class="small">© Copyright Stiftelsen Skjulte Skatters Forlag</p>
	</footer>
</template>

<script>


export default {
  
}
</script>

