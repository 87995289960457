var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("h4", [_vm._v(_vm._s(_vm.$t("search.year-filter")))]),
    _c("div", { staticClass: "range-slider" }, [
      _c(
        "div",
        {
          staticClass: "range-wrap",
          style: `--a: ${_vm.value1}; --b: ${_vm.value2}; --min: ${_vm.minYear}; --max: ${_vm.maxYear}`,
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value1,
                expression: "value1",
              },
            ],
            attrs: {
              min: _vm.minYear,
              max: _vm.maxYear,
              step: "1",
              type: "range",
            },
            domProps: { value: _vm.value1 },
            on: {
              __r: function ($event) {
                _vm.value1 = $event.target.value
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value2,
                expression: "value2",
              },
            ],
            attrs: {
              min: _vm.minYear,
              max: _vm.maxYear,
              step: "1",
              type: "range",
            },
            domProps: { value: _vm.value2 },
            on: {
              __r: function ($event) {
                _vm.value2 = $event.target.value
              },
            },
          }),
        ]
      ),
      _c("p", { staticClass: "range-values" }, [
        _c("span", [_vm._v(_vm._s(_vm.minYearValue || "-"))]),
        _c("span", [_vm._v(_vm._s(_vm.maxYearValue || "-"))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }