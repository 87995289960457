var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "search-result" },
    [
      _c(
        "router-link",
        { attrs: { to: _vm.getReaderRoute(_vm.result.document) } },
        [
          _c("div", { staticClass: "result-article" }, [
            _c("h4", [_vm._v(_vm._s(_vm.result.document.chapter))]),
            _c("p", {
              staticClass: "small",
              domProps: { innerHTML: _vm._s(_vm.highlight) },
            }),
          ]),
          _c(
            "div",
            { staticClass: "result-book" },
            [
              _c("book-card-cover", {
                attrs: { "cover-id": _vm.result.document.bookCoverImageId },
              }),
              _c("div", { staticClass: "book-details" }, [
                _c("h5", [_vm._v(_vm._s(_vm.result.document.bookName))]),
                _c("p", { staticClass: "small" }, [
                  _vm._v(
                    _vm._s(_vm.result.document.authorFullName) +
                      " · " +
                      _vm._s(_vm.format(_vm.result.document.publishingDate))
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }