var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticStyle: { width: "100%" } },
    [
      _vm._l(_vm.groupedList, function (arr, gridName) {
        return [
          _c("h1", { key: gridName }, [_vm._v(_vm._s(gridName))]),
          _vm._l(
            arr.filter((el) => _vm.isSearchResult(el.title)),
            function (element) {
              return _c(
                "article",
                { key: element.id },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "read-publication",
                          params: {
                            bookId: element.bookId,
                            year: element.year,
                            month: element.month,
                            chapterId: element.chapterId,
                            parent: _vm.$route,
                          },
                        },
                      },
                    },
                    [
                      _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(_vm.highlightedName(element.title)),
                        },
                      }),
                      _c("h5", [
                        _vm._v(
                          _vm._s(element.year) + ", " + _vm._s(element.month)
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }