var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", [
    _vm.hasFreeOption
      ? _c(
          "section",
          [
            _c("FreeOptionText"),
            _c("div", { staticClass: "checkbox-input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.freeOption,
                    expression: "freeOption",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.freeOption)
                    ? _vm._i(_vm.freeOption, null) > -1
                    : _vm.freeOption,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.freeOption,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.freeOption = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.freeOption = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.freeOption = $$c
                    }
                  },
                },
              }),
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm.$t("subscription.form.freeOption", {
                      publicationName: this.formData.bookName,
                    })
                  )
                ),
              ]),
            ]),
          ],
          1
        )
      : _c("h5", [_vm._v(_vm._s(_vm.$t("subscription.form.title")))]),
    _c("section", { attrs: { id: "payerInformation" } }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.name")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.payerName,
            expression: "form.payerName",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.form.payerName },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "payerName", $event.target.value)
          },
        },
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.email")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.payerEmail,
            expression: "form.payerEmail",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.form.payerEmail },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "payerEmail", $event.target.value)
          },
        },
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.address")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.payerAddress,
            expression: "form.payerAddress",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.form.payerAddress },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "payerAddress", $event.target.value)
          },
        },
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.city")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.payerCity,
            expression: "form.payerCity",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.form.payerCity },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "payerCity", $event.target.value)
          },
        },
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.postalCode")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.payerPostalCode,
            expression: "form.payerPostalCode",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.form.payerPostalCode },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "payerPostalCode", $event.target.value)
          },
        },
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.state")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.payerState,
            expression: "form.payerState",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.form.payerState },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "payerState", $event.target.value)
          },
        },
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.country")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.payerCountry,
            expression: "form.payerCountry",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.form.payerCountry },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "payerCountry", $event.target.value)
          },
        },
      }),
    ]),
    _vm.formData.hasMultiModes && !_vm.freeOption
      ? _c("section", { attrs: { id: "giftSection" } }, [
          _c("div", { staticClass: "checkbox-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.giftOption,
                  expression: "form.giftOption",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.giftOption)
                  ? _vm._i(_vm.form.giftOption, null) > -1
                  : _vm.form.giftOption,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.form.giftOption,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.form, "giftOption", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "giftOption",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "giftOption", $$c)
                  }
                },
              },
            }),
            _c("label", [
              _vm._v(_vm._s(_vm.$t("subscription.form.giftOption"))),
            ]),
          ]),
          _vm.form.giftOption
            ? _c("section", { attrs: { id: "receiverInformation" } }, [
                _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.name")))]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.receiverName,
                      expression: "form.receiverName",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.receiverName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "receiverName", $event.target.value)
                    },
                  },
                }),
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("subscription.form.address"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.receiverAddress,
                      expression: "form.receiverAddress",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.receiverAddress },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "receiverAddress", $event.target.value)
                    },
                  },
                }),
                _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.city")))]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.receiverCity,
                      expression: "form.receiverCity",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.receiverCity },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "receiverCity", $event.target.value)
                    },
                  },
                }),
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("subscription.form.postalCode"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.receiverPostalCode,
                      expression: "form.receiverPostalCode",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.receiverPostalCode },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "receiverPostalCode",
                        $event.target.value
                      )
                    },
                  },
                }),
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("subscription.form.state"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.receiverState,
                      expression: "form.receiverState",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.receiverState },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "receiverState", $event.target.value)
                    },
                  },
                }),
                _c("label", [
                  _vm._v(_vm._s(_vm.$t("subscription.form.country"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.receiverCountry,
                      expression: "form.receiverCountry",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.receiverCountry },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "receiverCountry", $event.target.value)
                    },
                  },
                }),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("label", [_vm._v(_vm._s(_vm.$t("subscription.form.price")))]),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.form.price,
          expression: "form.price",
        },
      ],
      attrs: { type: "text", readonly: true },
      domProps: { value: _vm.form.price },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.form, "price", $event.target.value)
        },
      },
    }),
    _c(
      "button",
      {
        staticClass: "button-main small",
        attrs: { disabled: !_vm.formCompleted },
        on: { click: _vm.subscribe },
      },
      [_vm._v(" " + _vm._s(_vm.$t("subscription.subscribe-button")) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }