var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "center user-voice-banner" }, [
    _c(
      "a",
      {
        staticClass: "user-voice-illustration",
        attrs: { href: "#", target: "_blank" },
        on: { click: _vm.goToUserVoice },
      },
      [
        _c("img", {
          attrs: { src: "/img/user-voice-illustration.svg", alt: "User Voice" },
        }),
      ]
    ),
    _c("div", { staticClass: "content" }, [
      _c("h4", [_vm._v(_vm._s(_vm.$t("app.user-voice.title")))]),
      _c("p", [_vm._v(_vm._s(_vm.$t("app.user-voice.description")))]),
      _c(
        "button",
        { staticClass: "button-main small", on: { click: _vm.goToUserVoice } },
        [_vm._v(_vm._s(_vm.$t("app.user-voice.button")))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }