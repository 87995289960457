var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "container" }, [
    _vm.typedList.length > 0
      ? _c(
          "section",
          { staticClass: "center", class: { "popular-authors": _vm.isAuthor } },
          [
            _c(
              "div",
              { staticClass: "section-header border" },
              [
                _c("h5", [_vm._v(_vm._s(_vm.title))]),
                _vm.$parent.searchQuery != undefined && _vm.firstChild
                  ? _c("Search", {
                      attrs: { notEmpty: _vm.$parent.searchQuery.length > 0 },
                      on: {
                        "query-changed": (query) => {
                          _vm.$parent.searchQuery = query
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "section",
              {
                class: [
                  _vm.isPublication ? "subscriptions-grid" : "grid",
                  { articles: _vm.isArticle },
                ],
              },
              [
                _vm.isArticle && _vm.isList
                  ? _c("ArticleList", {
                      attrs: {
                        list: _vm.list,
                        searchQuery: _vm.$parent.searchQuery,
                      },
                    })
                  : _vm.isAuthor && _vm.isList
                  ? _c("AuthorList", {
                      attrs: {
                        list: _vm.list,
                        searchQuery: _vm.$parent.searchQuery,
                      },
                    })
                  : _vm.isPublication
                  ? _vm._l(_vm.typedList, function (element) {
                      return _c("PublicationCard", {
                        key: element.id,
                        attrs: { element: element },
                      })
                    })
                  : _vm._l(_vm.typedList, function (element) {
                      return _c("Card", {
                        key: element.id,
                        attrs: {
                          element: element,
                          searchQuery: _vm.$parent.searchQuery,
                        },
                      })
                    }),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }