var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("a", {
      staticClass: "font-smaller button-circular white",
      class: { dark: _vm.nightMode, disabled: _vm.isMinFontSize },
      attrs: { alt: "Font Smaller" },
      on: {
        click: function ($event) {
          return _vm.changeFontSize(-0.1)
        },
      },
    }),
    _c("a", {
      staticClass: "font-bigger button-circular white",
      class: { dark: _vm.nightMode, disabled: _vm.isMaxFontSize },
      attrs: { alt: "Font Bigger" },
      on: {
        click: function ($event) {
          return _vm.changeFontSize(0.1)
        },
      },
    }),
    _c("section", { staticClass: "sidebar" }, [
      _c("div", { staticClass: "section-header" }, [
        _c("h5", [
          _vm._v(_vm._s(_vm.$t("book-index.chapters")) + " "),
          _c("span", [_vm._v(_vm._s(_vm.chaptersLength))]),
        ]),
      ]),
      _c("section", { staticClass: "list" }, [
        _c(
          "ol",
          { staticClass: "chapters" },
          _vm._l(
            _vm.chapters.sort((a, b) => parseInt(a.id) - parseInt(b.id)),
            function (chapter) {
              return _c(
                "li",
                {
                  key: chapter.id,
                  class: [
                    "chapter",
                    { "current-chapter": chapter.id == _vm.selectedChapter },
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.$emit("chapterChanged", chapter.id)
                    },
                  },
                },
                [
                  _c("a", { attrs: { alt: chapter.title } }, [
                    _c("h5", [_vm._v(_vm._s(chapter.title))]),
                  ]),
                ]
              )
            }
          ),
          0
        ),
      ]),
    ]),
    _c("section", {
      staticClass: "overlay",
      on: {
        click: function ($event) {
          return _vm.$emit("close")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }