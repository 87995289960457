var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSearchResult(_vm.name)
    ? _c(
        "article",
        { class: _vm.articleClass },
        [
          _c(
            "router-link",
            { attrs: { to: _vm.route } },
            [
              _c("CardCover", {
                attrs: {
                  "cover-id": _vm.element.coverId,
                  name: _vm.name,
                  type: _vm.stringType,
                },
              }),
              _c("h4", {
                domProps: { innerHTML: _vm._s(_vm.highlightedName(_vm.name)) },
              }),
              _vm.element.author != null
                ? _c("p", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.element.author.fullName)),
                  ])
                : _vm._e(),
              _vm.availability != null
                ? _c("h6", [_vm._v(_vm._s(_vm.availability))])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }