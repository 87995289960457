var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { staticClass: "publication-header container" }, [
        _c("section", { staticClass: "center small" }, [
          _c("h2", [_vm._v(_vm._s(_vm.book.title))]),
          _c("h5", [
            _vm._v(
              _vm._s(_vm.$t("book-index.since")) + " " + _vm._s(_vm.book.year)
            ),
          ]),
        ]),
      ]),
      _c("Subscription", { attrs: { book: _vm.book } }),
      _c("section", { staticClass: "container" }, [
        _c("section", { staticClass: "center small" }, [
          _c(
            "div",
            { staticClass: "section-header publication" },
            [
              _c("h5", [
                _vm._v(_vm._s(_vm.$t("book-index.publication-issues-from"))),
              ]),
              _c("la-dropdown", {
                attrs: {
                  options: _vm.dropdownOptions,
                  "button-text": _vm.yearsText,
                  "inner-text": _vm.$t("book-index.choose-year"),
                },
                on: { click: _vm.setYear },
              }),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "list" },
            [
              _vm._l(
                _vm.publicationsFilteredByYear,
                function (publicationYear) {
                  return _vm._l(
                    publicationYear.months,
                    function (publicationMonth) {
                      return _c("publication-month", {
                        key: `${publicationMonth.year}-${publicationMonth.monthName}`,
                        attrs: {
                          "publication-month": publicationMonth,
                          book: _vm.book,
                        },
                      })
                    }
                  )
                }
              ),
              _vm.nextYear !== null
                ? _c(
                    "a",
                    {
                      staticClass: "pagination-button",
                      on: {
                        click: function ($event) {
                          return _vm.switchToNextYear()
                        },
                      },
                    },
                    [
                      _c("h5", [
                        _vm._v(
                          _vm._s(_vm.$t("book-index.show")) +
                            " " +
                            _vm._s(_vm.nextYear)
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }