var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "search-result" },
    [
      _c(
        "router-link",
        {
          staticClass: "result-author-only",
          attrs: {
            to: {
              name: "author-index",
              params: { authorId: _vm.result.id, parent: _vm.$route },
            },
          },
        },
        [
          _vm.result.coverId != null
            ? _c("card-cover", {
                attrs: { "cover-id": _vm.result.coverId, type: "author" },
              })
            : _vm._e(),
          _c("h4", [_vm._v(" " + _vm._s(_vm.result.fullName))]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }