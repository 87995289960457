var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadingTop
        ? _c("loading-spinner", { attrs: { id: "scrollerTop" } })
        : _vm._e(),
      _vm._t("default"),
      _vm.loadingBottom ? _c("loading-spinner") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }