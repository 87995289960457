var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPlayer
    ? _c("div", { staticClass: "text-to-speech-player-outer" }, [
        _c("div", { staticClass: "text-to-speech-player-inner" }, [
          _c("div", { staticClass: "progress-bar" }, [
            _c("div", {
              staticClass: "progress-bar-progress",
              style: { width: _vm.playingPercentage + "%" },
            }),
          ]),
          _c("div", { staticClass: "player-info" }, [
            _c("h4", { staticClass: "chapter-id" }, [
              _vm._v("#" + _vm._s(_vm.article.chapterId)),
            ]),
            _c("h3", { staticClass: "article-title" }, [
              _vm._v(_vm._s(_vm.article.title)),
            ]),
            _c("h4", { staticClass: "article-clock" }, [
              _vm._v(_vm._s(_vm.articleClock.time)),
            ]),
            _c("a", {
              staticClass: "button-main play-pause-button button-circular",
              class: [_vm.isPlaying ? "pause-icon" : "play-icon"],
              on: {
                click: function ($event) {
                  return _vm.toggleSpeak(_vm.article)
                },
              },
            }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }