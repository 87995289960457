const keys = {};

keys.AUTH0_DOMAIN = 'https://login.bcc.no';
keys.AUTH0_CLIENT_ID = 'X0ac7C8sROIhEzRGLJPFpLCZAlKGK4KV';
keys.API_AUDIENCE = 'https://literatureapi.bcc.no';
keys.SUBSCRIPTION_FORM_URLS = { 
    312: 'https://band.dcg-deutschland.de/'
};

keys.API_BASE_PATH = 'https://api.sssf.life/';
keys.APP_INSIGHTS = '14dc7370-d646-4de8-ae9b-71b8519fc3e3';
keys.APP_URL = 'https://sssf.life/';

export default keys;