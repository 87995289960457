var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { style: { top: "0 !important" } },
    [
      _vm.showBackButton
        ? _c("a", {
            class: "header__back-button",
            attrs: { href: "#", alt: "Back Button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goBack.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _c("la-dropdown", {
        attrs: {
          options: _vm.dropdownOptions,
          "button-text": _vm.currentLanguage,
          "inner-text": _vm.$t("app.select-language"),
        },
        on: { click: _vm.setLanguage },
      }),
      _c("a", {
        staticClass: "header__night-mode",
        class: { dark: _vm.nightMode },
        attrs: { href: "#", alt: "Night mode" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleNightMode.apply(null, arguments)
          },
        },
      }),
      _vm.showBackButton
        ? _c("h4", [_vm._v(_vm._s(_vm.pageName))])
        : _c(
            "nav",
            _vm._l(["books", "authors"], function (menu) {
              return _c(
                "router-link",
                {
                  key: menu,
                  class: { current: _vm.$t(`${menu}.${menu}`) == _vm.pageName },
                  attrs: { to: { name: menu } },
                },
                [_vm._v(" " + _vm._s(_vm.$t(`${menu}.${menu}`)) + " ")]
              )
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }