import { render, staticRenderFns } from "./card.vue?vue&type=template&id=76168b3a&scoped=true&"
import script from "./card.vue?vue&type=script&lang=js&"
export * from "./card.vue?vue&type=script&lang=js&"
import style0 from "./card.vue?vue&type=style&index=0&id=76168b3a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76168b3a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/bcc-literature/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('76168b3a')) {
      api.createRecord('76168b3a', component.options)
    } else {
      api.reload('76168b3a', component.options)
    }
    module.hot.accept("./card.vue?vue&type=template&id=76168b3a&scoped=true&", function () {
      api.rerender('76168b3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/grid/tiles/card.vue"
export default component.exports