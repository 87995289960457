var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("h4", [_vm._v(_vm._s(_vm.facetTitle))]),
    _c(
      "form",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: () => _vm.disableOptions(),
            expression: "() => disableOptions()",
          },
        ],
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchQuery,
              expression: "searchQuery",
            },
          ],
          staticClass: "search-filter",
          class: { open: !_vm.hideOptions },
          attrs: {
            type: "text",
            autocomplete: "off",
            name: "Search",
            placeholder: _vm.facetPlaceholder,
          },
          domProps: { value: _vm.searchQuery },
          on: {
            click: function ($event) {
              return _vm.toggleOptions()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.searchQuery = $event.target.value
            },
          },
        }),
        _c(
          "div",
          {
            staticClass: "custom-select",
            class: {
              hide: _vm.hideOptions,
              "no-result": _vm.options.length === 0,
            },
          },
          [
            _c(
              "ul",
              _vm._l(_vm.options, function (option) {
                return _c(
                  "li",
                  {
                    key: option.value,
                    on: {
                      click: function ($event) {
                        return _vm.toggleSelection(option.value)
                      },
                    },
                  },
                  [
                    _c("input", {
                      attrs: { type: "checkbox", name: option.value },
                    }),
                    _c("span", [_vm._v(_vm._s(option.value))]),
                  ]
                )
              }),
              0
            ),
            _vm.options.length === 0
              ? _c("div", { staticClass: "no-match" }, [
                  _vm._v(_vm._s(_vm.$t("search.filters.no-match")) + " ..."),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
    _vm.selections.length
      ? _c("div", { staticClass: "search-selection" }, [
          _c(
            "ul",
            _vm._l(_vm.selections, function (selection) {
              return _c("li", { key: selection }, [
                _c("img", {
                  attrs: { src: "/assets/icons/icon_24_close-small.svg" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleSelection(selection)
                    },
                  },
                }),
                _c("span", [_vm._v(_vm._s(selection))]),
              ])
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }