var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "announcement" }, [
      _c("p", [
        _c("b", [_vm._v("Kjære ungdom som fyller 20 i år!")]),
        _c("br"),
        _c("br"),
        _vm._v(
          " Stiftelsen Skjulte Skatters Forlag (SSSF) har i flere år tilbudt "
        ),
        _c("u", [_vm._v("den norske utgaven")]),
        _vm._v(" av bladet Skjulte Skatter fritt til "),
        _c("b", [_vm._v("alle")]),
        _vm._v(
          " ungdommer det året de fyller 20 år. Er du interessert i ditt eget abonnement på bladet Skjulte Skatter, kan du bestille det ved å fylle ut skjemaet."
        ),
        _c("br"),
        _c("br"),
        _vm._v(" Du vil da få fritt tilsendt "),
        _c("u", [_vm._v("den norske utgave")]),
        _vm._v(
          " av bladet Skjulte Skatter i ett år. (Dersom du allerede er abonnent og har betalt ditt abonnement i år, vil du få fritt abonnement neste år.)"
        ),
        _c("br"),
        _vm._v(
          " På slutten av året vil du motta faktura for neste år på vanlig måte."
        ),
        _c("br"),
        _vm._v(
          " Ønsker du da å fortsette å motta bladet til oppbyggelse, kan du betale denne!"
        ),
        _c("br"),
        _c("br"),
        _vm._v(" Hilsen Stiftelsen Skjulte Skatters Forlag "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }