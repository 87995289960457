var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: () => (_vm.show = false),
          expression: "() => show = false",
        },
      ],
      staticClass: "dropdown",
    },
    [
      _c(
        "button",
        {
          staticClass: "dropdown-button",
          on: {
            click: function ($event) {
              _vm.show = !_vm.show
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
      ),
      _c(
        "section",
        { staticClass: "custom-select", class: { show: _vm.show } },
        [
          _c("h6", [_vm._v(_vm._s(_vm.innerText))]),
          _c(
            "ul",
            [
              _vm.defaultValue != null
                ? _c(
                    "li",
                    {
                      attrs: { value: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("click", null)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.defaultValue))]
                  )
                : _vm._e(),
              _vm._l(_vm.options, function (option) {
                return _c(
                  "li",
                  {
                    key: option.value,
                    attrs: { value: option.value },
                    on: {
                      click: function ($event) {
                        _vm.$emit("click", option.value)
                        _vm.show = false
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(option.title) + " ")]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }