var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "center song-treasures-notice" }, [
    _vm._m(0),
    _c("p", {
      staticClass: "notice-description",
      domProps: { innerHTML: _vm._s(_vm.$t("app.song-treasures-notice")) },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "song-treasures-logo",
        attrs: { href: "https://songtreasures.org", target: "_blank" },
      },
      [
        _c("img", {
          attrs: {
            src: "/img/song-treasures-logo-dark.svg",
            alt: "SongTreasures",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }