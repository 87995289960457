var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("app-header", {
        attrs: {
          backButtonRoute: _vm.$route.params.parent
            ? _vm.$route.params.parent
            : { name: "books" },
          pageName: _vm.$t("book-index.book-index"),
        },
      }),
      _c(
        "loader",
        [
          _vm.notFound
            ? _c("not-found")
            : _vm.isPublication
            ? _c("book-overview-publication")
            : _c("book-overview-chapters"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }