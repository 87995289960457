var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("app-header", {
        attrs: {
          backButtonRoute: { name: "books" },
          pageName: _vm.$t("search.search"),
        },
      }),
      _c("section", { staticClass: "container search-header" }, [
        _c(
          "section",
          { staticClass: "center small", staticStyle: { padding: "0 10px" } },
          [_c("search-box")],
          1
        ),
      ]),
      _c("section", { staticClass: "container content" }, [
        _c("section", { staticClass: "center medium" }, [
          _c(
            "aside",
            {
              staticClass: "temp",
              style: {
                top: _vm.isMobile
                  ? _vm.isStandalone
                    ? "48px"
                    : "95px"
                  : "unset",
              },
              attrs: { id: "filters" },
            },
            [
              !_vm.isMobile
                ? _c("div", { staticClass: "header" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.$t("search.filters.title")))]),
                    _vm.anyActiveFilter
                      ? _c(
                          "p",
                          {
                            staticClass: "remove-filters",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeAllFilters()
                              },
                            },
                          },
                          [
                            _c("a", { attrs: { href: "#" } }, [
                              _vm._v(_vm._s(_vm.$t("search.filters.clear"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c(
                "section",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: () => (_vm.showFilters = false),
                      expression: "() => showFilters = false",
                    },
                  ],
                  staticClass: "filter",
                  class: _vm.showFilters ? "open" : "closed",
                },
                [
                  _vm.isMobile
                    ? _c(
                        "div",
                        {
                          staticClass: "header",
                          on: {
                            click: function ($event) {
                              _vm.showFilters = !_vm.showFilters
                            },
                          },
                        },
                        [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("search.filters.title"))),
                          ]),
                          _vm.anyActiveFilter
                            ? _c(
                                "p",
                                {
                                  staticClass: "remove-filters",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeAllFilters()
                                    },
                                  },
                                },
                                [
                                  _c("a", { attrs: { href: "#" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("search.filters.clear"))
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  !_vm.isMobile || _vm.showFilters
                    ? _c(
                        "div",
                        { staticClass: "filters-wrapper" },
                        [
                          _c("search-facet", {
                            attrs: {
                              facetName: "BookName",
                              facetTitle: _vm.$t("search.filters.books-title"),
                              facetPlaceholder: _vm.$t(
                                "search.filters.search-for-book"
                              ),
                            },
                          }),
                          _c("search-facet", {
                            attrs: {
                              facetName: "AuthorFullName",
                              facetTitle: _vm.$t(
                                "search.filters.authors-title"
                              ),
                              facetPlaceholder: _vm.$t(
                                "search.filters.search-for-author"
                              ),
                            },
                          }),
                          _c("year-filter", { ref: "yearFilter" }),
                          _c("section", { staticClass: "exact-match" }, [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("search.exact-match"))),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.exactMatch,
                                  expression: "exactMatch",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "search-exact-text",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.exactMatch)
                                  ? _vm._i(_vm.exactMatch, null) > -1
                                  : _vm.exactMatch,
                              },
                              on: {
                                click: _vm.updateExactMatch,
                                change: function ($event) {
                                  var $$a = _vm.exactMatch,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.exactMatch = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.exactMatch = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.exactMatch = $$c
                                  }
                                },
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _c(
            "section",
            { staticClass: "search-results list" },
            [
              !_vm.showSpinner
                ? _c("h3", [
                    _vm._v(
                      _vm._s(
                        this.noOfResults == 0
                          ? _vm.$t("search.no-results")
                          : this.noOfResults +
                              " " +
                              _vm.$t("search.search-results")
                      )
                    ),
                  ])
                : _vm._e(),
              _vm._l(_vm.authorResults, function (result) {
                return _c("search-result-author", {
                  key: result.Id,
                  attrs: { result: result },
                })
              }),
              _vm._l(_vm.bookResults, function (result) {
                return _c("search-result-book", {
                  key: result.Id,
                  attrs: { result: result },
                })
              }),
              _vm._l(_vm.results, function (result, index) {
                return _c("search-result", {
                  key: result.articleId,
                  attrs: { result: result, rank: index + 1 },
                })
              }),
              _vm.showSpinner ? _c("loading-spinner") : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }