export const BookType = {
    // Translation?
    BLAD: 1,
    HEFTE: 2,
    ARK: 3,
    BOK: 4,
    TRAKTAT: 5,
    PAPERBACK: 6,
    SPIRALBOK: 7,
    MØTEREFERAT: 9
}