var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.book.bookType === _vm.BookType.BLAD
      ? _c("section", [_c("h1", [_vm._v(_vm._s(_vm.bookTitle))])])
      : _c("section", [
          _c("h1", [_vm._v(_vm._s(_vm.book.title))]),
          _c(
            "h6",
            [
              _vm.book.author
                ? [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("book-index.written-by")) +
                        " " +
                        _vm._s(_vm.book.author.fullName) +
                        " "
                    ),
                  ]
                : _vm._e(),
              _vm.book.author && _vm.book.year ? [_vm._v(" · ")] : _vm._e(),
              _vm.book.year
                ? [_vm._v(" " + _vm._s(_vm.book.year) + " ")]
                : _vm._e(),
            ],
            2
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }