var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("app-header", {
        attrs: {
          backButtonRoute: _vm.$route.params.parent
            ? _vm.$route.params.parent
            : { name: "authors" },
          pageName: _vm.$t("authors.author-index"),
        },
      }),
      _c("loader", [
        _c("section", [
          _vm.author != null
            ? _c("section", { staticClass: "author-header container" }, [
                _c(
                  "section",
                  { staticClass: "center small" },
                  [
                    _vm.author.coverId != null
                      ? _c("card-cover", {
                          attrs: {
                            name: _vm.author.fullName,
                            "cover-id": _vm.author.coverId,
                            type: "author",
                          },
                        })
                      : _vm._e(),
                    _c("h2", [_vm._v(_vm._s(_vm.author.fullName))]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "section",
            { staticClass: "container" },
            [
              _c(
                "SearchableGrids",
                [
                  _vm.books.length
                    ? _c("Grid", {
                        attrs: {
                          list: _vm.books,
                          title: _vm.$t("books.books-all"),
                          layout: _vm.GridLayout.TILES,
                          type: _vm.GridType.BOOK,
                        },
                      })
                    : _vm._e(),
                  _vm.articles.length
                    ? _c("Grid", {
                        attrs: {
                          list: _vm.articles,
                          title: _vm.$t("articles.articles-all"),
                          layout: _vm.GridLayout.LIST,
                          type: _vm.GridType.ARTICLE,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !_vm.articles.length && !_vm.books.length
                ? _c("h5", { staticClass: "empty-state" }, [
                    _vm._v(_vm._s(_vm.$t("search.no-results"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }