var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "shareUrlModal",
            maxWidth: 768,
            maxHeight: 200,
            adaptive: "",
          },
        },
        [
          _c("div", { staticStyle: { padding: "25px" } }, [
            _c("h4", [_vm._v(_vm._s(_vm.$t("share.share-link")))]),
            _c(
              "span",
              { staticClass: "close-times", on: { click: _vm.close } },
              [_vm._v("×")]
            ),
            _c("br"),
            _c("br"),
            _c("input", {
              staticClass: "share-link",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.url },
            }),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.url,
                    expression: "url",
                    arg: "copy",
                  },
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:success",
                    value: () => {
                      this.copyText = _vm.$t("share.copy-success")
                      this.copyClass = "success"
                    },
                    expression:
                      "() => { this.copyText = $t('share.copy-success'); this.copyClass = 'success' }",
                    arg: "success",
                  },
                ],
                class: ["button-main", "small", "copy", this.copyClass],
              },
              [_vm._v(" " + _vm._s(_vm.copyText) + " ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }