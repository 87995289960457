var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticStyle: { width: "100%" } },
    _vm._l(_vm.groupedList, function (arr, gridName) {
      return _c(
        "article",
        { key: gridName, staticClass: "letter" },
        [
          _c("h1", [_vm._v(_vm._s(gridName))]),
          _vm._l(arr, function (element) {
            return _c(
              "p",
              { key: element.id },
              [
                _c("router-link", {
                  staticClass: "author-name",
                  attrs: {
                    to: {
                      name: "author-index",
                      params: { authorId: element.id },
                    },
                  },
                  domProps: {
                    innerHTML: _vm._s(_vm.highlightedName(element.fullName)),
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }