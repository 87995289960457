var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      class: [
        "subscription-signup",
        { open: _vm.showForm },
        { confirmed: _vm.showConfirmation },
      ],
    },
    [
      _c("h4", [
        _vm._v(
          _vm._s(_vm.$t("subscription.subscribe-to")) +
            " " +
            _vm._s(_vm.book.title)
        ),
      ]),
      _c("p", { staticClass: "small" }, [
        _vm._v(
          _vm._s(
            _vm.$t("subscription.subtitle", { publicationName: _vm.book.title })
          )
        ),
      ]),
      _c("BookCover", { attrs: { "cover-id": _vm.book.coverId } }),
      _vm.showForm
        ? _c("a", {
            staticClass: "button-circular minimize-button small",
            on: {
              click: function ($event) {
                _vm.showForm = false
              },
            },
          })
        : _vm._e(),
      _vm.showForm
        ? _c("SubscriptionForm", {
            attrs: { formData: _vm.subscriptionInfo },
            on: { subscribe: _vm.subscribe },
          })
        : _vm._e(),
      _vm.showConfirmation
        ? _c("Confirmation", { attrs: { success: _vm.success } })
        : _vm._e(),
      !_vm.showForm && !_vm.showConfirmation
        ? _c("div", { staticClass: "signup-cta" }, [
            _c(
              "a",
              {
                staticClass: "button-main small",
                on: { click: _vm.showFormOrGoToPublication },
              },
              [_vm._v(_vm._s(_vm.$t("subscription.subscribe-button")))]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }