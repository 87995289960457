var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "documentview-" + _vm.article.chapterId } }, [
    _c(
      "div",
      {
        staticClass: "documentview-container",
        attrs: { id: "documentview-container-" + _vm.article.chapterId },
      },
      [
        _c("iframe", {
          attrs: {
            width: "440px",
            height: "330px",
            src: _vm.article.documentUrl,
            frameborder: "0",
            allowfullscreen: "true",
            referrerpolicy: "origin-when-cross-origin",
            allowtransparency: "true",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }