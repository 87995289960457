var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "button-main small article-tts-btn play-pause-icon",
      class: [
        _vm.isArticlePlaying ? "pause-icon" : "play-icon",
        { "bmm-icon": _vm.audioBookUrl },
      ],
      on: {
        click: function ($event) {
          return _vm.toggleSpeak({
            ..._vm.article,
            audioBookUrl: _vm.audioBookUrl,
            bookTitle: _vm.bookTitle,
          })
        },
      },
    },
    [_vm._v(_vm._s(_vm.speechBtnText))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }